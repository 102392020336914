import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPanelById } from "../../../features/panel/panelSlice";

function PanelTable({panelId}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { panelDetails, paneloading } = useSelector((state) => state.panel);

  const columns = [
    {
      title: "Panelist",
      dataIndex: "pmeName",
      render: (text) => text,
    },
    {
      title: "Roles",
      dataIndex: "panRole",
      render: (text) => text,
    },
    {
      title: "Email",
      dataIndex: "pmeEmail",
      render: (text) => text,
    },
    {
      title: "Phone Number",
      dataIndex: "pmePhone",
      render: (text) => text,
    },
  ];

  async function fetchPanelDetailsData() {
    await dispatch(
      fetchPanelById({
        paId:panelId,
      })
    );
  }

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  useEffect(() => {
    fetchPanelDetailsData();
  }, [panelId]);

  return (
    <>
      {panelDetails?.map((panel) => (
        <Table
          key={panel?.paId}
          rowSelection={true}
          size={"middle"}
          bordered={true}
          pagination={{
            position: ["bottomCenter"],
          }}
          rowKey={panel?.paId}
          columns={tableColumns}
          dataSource={panel.children || []}
          scroll={"scroll"}
          className="custom-table"
          loading={paneloading}
          title={(record) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>{panel?.paName}</span>
              </div>
            );
          }}
        />
      ))}
    </>
  );
}

export default PanelTable;
