import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  applicantJobApplication: [],
  jobApplicationLoading: false, 
  refetch:false, 
  jobApplicationPerJob: [],
  institutionApplicants: [],
  applicantInstitutionJob:[],

  applicantsCount : 0
};

export const saveJobApplication = createAsyncThunk(
  "cv/saveJobApplication",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveJobApplication.action`, data);
    return res.data;
  }
);

export const saveJobApplicationsByIds = createAsyncThunk(
  "cv/saveJobApplicationsByIds",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveJobApplicationsByIds.action`, data);
    return res.data;
  }
);

export const fetchJobApplications = createAsyncThunk(
  "cv/fetchJobApplications",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobApplications.action?japUsrId=${data?.japUsrId}`
    );
    return res.data?.data?.result;
  }
);

export const fetchJobApplicationsApplicant = createAsyncThunk(
  "cv/fetchJobApplicationsApplicant",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobApplications.action?japJobId=${data?.japJobId}&japProgressStatus=${data?.japProgressStatus}&japInstId=${data?.japInstId}`
    );
    return res.data?.data?.result;
  }
);

export const fetchInstitutionApplicant = createAsyncThunk(
  "cv/fetchInstitutionApplicant",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobApplications.action?japInstId=${data?.japInstId}`
    );
    return res.data?.data?.result;
  }
);


export const fetchApplicantInstitutionJob = createAsyncThunk(
  "cv/fetchApplicantInstitutionJob",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobApplications.action?japInstId=${data?.japInstId}&japUsrId=${data?.japUsrId}`
    );
    return res.data?.data?.result;
  }
);

export const fetchShortlistedCandidates = createAsyncThunk(
	'cv/fetchShortlistedCandidates',
	async (japJobId) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchJobApplications.action?japJobId=${japJobId}&japProgressStatus=SHORTLIST`,
		);
		return res.data
	},
);

export const fetchJobApplicationsApplicantById = createAsyncThunk(
  "cv/fetchJobApplicationsApplicantById",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobPlacements.action?japJaaId=${data?.japJaaId}&start=${data?.start ?? ''}&limit=${data?.limit ?? ''}`
    );
    return res.data?.data;
  }
);

 
export const applicantJobSlice = createSlice({
  name: "letter",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    },
     
    setRefetch:(state)=>{
      state.refetch = !state.refetch;
    },
   
    
  },
  extraReducers: (builder) => {
    builder

      .addCase(saveJobApplication.pending, (state) => {
        state.jobApplicationLoading = true;
      })
      .addCase(saveJobApplication.fulfilled, (state, action) => {
        state.jobApplicationLoading = false;
      })
      .addCase(saveJobApplication.rejected, (state) => {
        state.jobApplicationLoading = false;
      })

      .addCase(saveJobApplicationsByIds.pending, (state) => {
        state.jobApplicationLoading = true;
      })
      .addCase(saveJobApplicationsByIds.fulfilled, (state, action) => {
        state.jobApplicationLoading = false;
      })
      .addCase(saveJobApplicationsByIds.rejected, (state) => {
        state.jobApplicationLoading = false;
      })
      

      .addCase(fetchJobApplications.pending, (state) => {
        state.jobApplicationLoading = true;
      })
      .addCase(fetchJobApplications.fulfilled, (state, action) => {
        state.jobApplicationLoading = false;
        state.applicantJobApplication = action.payload;
      })
      .addCase(fetchJobApplications.rejected, (state) => {
        state.jobApplicationLoading = false;
      })

      .addCase(fetchJobApplicationsApplicant.pending, (state) => {
        state.jobApplicationLoading = true;
      })
      .addCase(fetchJobApplicationsApplicant.fulfilled, (state, action) => {
        state.jobApplicationLoading = false;
        state.jobApplicationPerJob = action.payload;
      })
      .addCase(fetchJobApplicationsApplicant.rejected, (state) => {
        state.jobApplicationLoading = false;
      })

     
       
      .addCase(fetchInstitutionApplicant.pending, (state) => {
        state.jobApplicationLoading = true;
      })
      .addCase(fetchInstitutionApplicant.fulfilled, (state, action) => {
        state.jobApplicationLoading = false;
        state.institutionApplicants = action.payload;
      })
      .addCase(fetchInstitutionApplicant.rejected, (state) => {
        state.jobApplicationLoading = false;
      })

      .addCase(fetchApplicantInstitutionJob.pending, (state) => {
        state.jobApplicationLoading = true;
      })
      .addCase(fetchApplicantInstitutionJob.fulfilled, (state, action) => {
        state.jobApplicationLoading = false;
        state.applicantInstitutionJob = action.payload;
      })
      .addCase(fetchApplicantInstitutionJob.rejected, (state) => {
        state.jobApplicationLoading = false;
      })

      .addCase(fetchShortlistedCandidates.pending, (state) => {
				state.jobApplicationLoading = true;
			})
			.addCase(fetchShortlistedCandidates.fulfilled, (state, action) => {
				state.jobApplicationLoading = false;
			})
			.addCase(fetchShortlistedCandidates.rejected, (state) => {
				state.jobApplicationLoading = false;
			})

    
      .addCase(fetchJobApplicationsApplicantById.pending, (state) => {
        state.jobApplicationLoading = true;
      })
      .addCase(fetchJobApplicationsApplicantById.fulfilled, (state, action) => {
        state.jobApplicationLoading = false;
        state.jobApplicationPerJob = action.payload?.result;
        state.applicantsCount =  action?.payload?.count;
      })
      .addCase(fetchJobApplicationsApplicantById.rejected, (state) => {
        state.jobApplicationLoading = false;
        state.applicantsCount =  0;
      })
      
      
  },
  
});

export default applicantJobSlice.reducer;
export const {
  handleSidenavFullWidth, 
  setRefetch, 
} = applicantJobSlice.actions;
