import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import emblem from '../assets/emblem.png';
import flagDivider from '../assets/flag_divider.svg';
import neaLogo from '../assets/NEA-logo.png';
import { Link, NavLink, useLocation } from 'react-router-dom';
import MaterialIcon from 'material-icons-react';
import { Dropdown, Menu, Badge } from 'antd';
import { logout } from '../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { formatImgPath } from '../utils';
import { fetchAgencyData } from '../features/agency/agencySlice';
import moment from 'moment';
import {
	deleteNotification,
	fetchNotifications,
	saveNotification,
} from '../features/global/globalSlice';
import { openNotificationWithIcon } from '../notifications';
import graduate from '../assets/GraduationCap.svg';
import dashboard from '../assets/dashboard.svg';
import advert from "../assets/Jobadvert.svg";
import pulse from '../assets/Pulse.svg';
import returns from '../assets/returns.svg';
import license from '../assets/license.svg';
import admin from '../assets/admin.svg';
import employees from '../assets/users.svg';

import { fetchMenuSidebar } from '../features/admin/adminSlice';

export default function DashboardLayout({ children }) {
	const { user } = useSelector((state) => state.auth);
	const { agencyObj } = useSelector((state) => state.agency);
	const { notifications } = useSelector((state) => state.global);
	const { menuSidebarData } = useSelector((state) => state.admin);
	
console.log("menuSidebarData",menuSidebarData)
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const [notOpen, setnotOpen] = useState(false);

	const usrNotObj = {
		usrInstId: user?.usrInstId,
		usrId: user?.usrId,
	};

	async function handleDelete(item) {
		await dispatch(deleteNotification(item));
		await dispatch(fetchNotifications(usrNotObj));
	}
	async function handleFetchMenuSidebarData() { 
		await dispatch(fetchMenuSidebar({
			mymAccess:true, 
			myId:user?.myId
		})); 
}



	async function handleReadNotification(item) {
		let unread = await { ...item, ntfnStatus: 'READ' };
		await dispatch(saveNotification(unread));
		await dispatch(fetchNotifications(usrNotObj));
	}

	async function handleNotifications() {
		if (user?.usrId) {
			let unread = await notifications?.filter(
				(item) =>
					String(item?.ntfnStatus).toUpperCase() ===
					String('UNREAD').toUpperCase()
			);
			await unread?.forEach((item) => {
				openNotificationWithIcon({
					key: item?.ntfnId,
					type: 'info',
					title: <span className='text-[18px] font-bold'>Notification</span>,
					content: (
						<span className='text-[16px] font-medium'>{item?.ntfnBody}</span>
					),
				});
				let notObj = {
					ntfnId: item?.ntfnId,
					ntfnStatus: 'RECEIVED',
				};
				dispatch(saveNotification(notObj));
			});
		}
	}

	async function handleLogout(e) {
		e.preventDefault();
		await dispatch(logout());
		await localStorage.clear();
		window.location.href = await 'https://kazi254.ke';
	}

	const notificationItems =
		notifications?.length === 0
			? [
					{
						key: '1',
						label: (
							<span className='text-[18px] font-semibold p-5 mt-5 absolute'>
								No notification available{' '}
							</span>
						),
					},
			  ]
			: notifications?.map((item) => {
					return {
						key: item?.ntfnId,
						label: (
							<>
								<div
									className={`flex items-start w-full min-h-[50px] z-[10000] p-3 ${
										item?.ntfnStatus === 'UNREAD' ||
										item?.ntfnStatus === 'RECEIVED'
											? 'bg-gray-200'
											: ''
									}`}>
									<div className='flex items-start w-full'>
										<img
											loading='lazy'
											src={
												item?.institutionImage
													? formatImgPath(item?.institutionImage)
													: 'https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png'
											}
											alt='profile'
											class='w-12 h-12 rounded-full'
										/>
										<div className='flex flex-col w-full ml-5'>
											<span className='text-[16px] font-bold text-black14'>
												{item?.institutionName}
											</span>
											<span className='w-full'>{item?.ntfnBody}</span>
											<span className='text-[14px] font-medium text-gray-500 my-1'>
												{moment(item?.ntfnCreatedTime)
													.startOf('hour')
													.fromNow()}
											</span>

											<div className='flex items-center w-full'>
												{item?.ntfnStatus === 'UNREAD' ||
												item?.ntfnStatus === 'RECEIVED' ? (
													<button
														onClick={() => handleReadNotification(item)}
														className='w-fit text-darkBlue mr-5 rounded-[8px]'>
														Mark as read
													</button>
												) : null}

												<button
													onClick={() => handleDelete(item?.ntfnId)}
													className='w-fit text-[#C8001B] rounded-[8px]'>
													Remove
												</button>
											</div>
										</div>
									</div>
								</div>
							</>
						),
					};
			  });

	const items = [
		{
			key: '1',
			label: (
				<>
					<div className='flex flex-col w-full h-full px-[.625rem] py-[1.25rem]'>
						<div className='flex items-center gap-x-[.5rem]'>
							<img
								src={
									user?.usrProfileImage
										? formatImgPath(user?.usrProfileImage)
										: 'https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png'
								}
								alt='avatar'
								className='w-[2.5rem] h-[2.5rem] object-cover rounded-full'
							/>

							<div className='flex flex-col gap-y-[.75rem]'>
								<span className='label_label_2 !text-[#000]'>
									{user?.usrFirstName} {user?.usrLastName ?? ''}
								</span>
								{/* <span className='paragraph_paragraph_3 !text-[#333]'>
											{user?.usrCode}
										</span> */}
							</div>
						</div>

						<div className='mt-[2.22rem] px-[.69rem] w-full flex flex-col gap-y-[1.81rem]'>
							<Link
								to={user?.usrRoleId === 5 ? '/admin-landing' : '/profile'}
								className='paragraph_paragraph_2 !text-[#333] hover:underline'>
								View profile
							</Link>
							<Link
								to='/settings'
								className='paragraph_paragraph_2 !text-[#333] hover:underline'>
								Settings
							</Link>

							<Link
								to='#'
								onClick={(e) => handleLogout(e)}
								className='paragraph_paragraph_2 !text-[#333] hover:underline'>
								Switch to another account
							</Link>
						</div>

						{Number(user?.usrRoleId) === 7 ? null : (
							<>
								<div className='w-full h-[0.0625rem] bg-[#F5F5F5] mt-[1.31rem]'></div>

								<Link
									to='#'
									onClick={(e) => handleLogout(e)}
									className='mt-[.81rem] px-[.69rem] w-full flex items-center gap-x-[.25rem]'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'>
										<path
											d='M11.25 16.75H12.75V12.75H16.75V11.25H12.75V7.25H11.25V11.25H7.25V12.75H11.25V16.75ZM12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12933 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z'
											fill='#616161'
										/>
									</svg>
									<span className='paragraph_paragraph_2 !text-[#333]'>
										Add job seeker account
									</span>
								</Link>
							</>
						)}

						<div className='w-full h-[0.0625rem] bg-[#F5F5F5] mt-[1.31rem]'></div>

						<Link
							to='#'
							onClick={(e) => handleLogout(e)}
							className='mt-[.81rem] px-[.69rem] w-full flex items-center gap-x-[.25rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'>
								<path
									d='M5.4001 21C4.9001 21 4.4751 20.825 4.1251 20.475C3.7751 20.125 3.6001 19.7 3.6001 19.2V5.3C3.6001 4.8 3.7751 4.375 4.1251 4.025C4.4751 3.675 4.9001 3.5 5.4001 3.5H12.1251V5H5.4001C5.33343 5 5.26676 5.03333 5.2001 5.1C5.13343 5.16667 5.1001 5.23333 5.1001 5.3V19.2C5.1001 19.2667 5.13343 19.3333 5.2001 19.4C5.26676 19.4667 5.33343 19.5 5.4001 19.5H12.1251V21H5.4001ZM16.1251 16.525L15.1001 15.425L17.5251 13H9.1251V11.5H17.5251L15.1001 9.075L16.1251 7.975L20.4001 12.25L16.1251 16.525Z'
									fill='#616161'
								/>
							</svg>
							<span className='paragraph_paragraph_2 !text-[#333]'>
								Log out
							</span>
						</Link>
					</div>
				</>
			),
		},
	];

	 
	const iconMap = {
		graduate: <img src={graduate} alt="graduate" className='w-[1.5rem] h-[1.5rem]' />,
		advert: <img src={advert} alt="Job Advert" className='w-[1.5rem] h-[1.5rem]' />,
		employees: <img src={employees} alt="Employees" className='w-[1.5rem] h-[1.5rem]' />,
		pulse: <img src={pulse} alt="pulse" className='w-[1.5rem] h-[1.5rem]' />,
		returns: <img src={returns} alt="returns" className='w-[1.5rem] h-[1.5rem]' />,
		license: <img src={license} alt="license" className='w-[1.5rem] h-[1.5rem]' />,
		admin: <img src={admin} alt="admin" className='w-[1.5rem] h-[1.5rem]' />, 
		dashboard: <img src={dashboard} alt="dashboard" className='w-[1.5rem] h-[1.5rem]' />, 
	  };
	const buildMenuItems = (menuData) => {
		return menuData?.map(item => ({
		  key: String(item?.mnsId), 
		  label: item?.mnsName,
		  icon: iconMap[item?.mnsIcon] || null,
		  mnsUrl: item?.mnsUrl, 
		}));
	  };
	 
	  const menuItems = buildMenuItems(menuSidebarData);
	  
	  const handleMenuClick = (item) => {
		const clickedItem = menuItems?.find(menuItem => menuItem?.key === item?.key);
		if (clickedItem && clickedItem?.mnsUrl) {
		  navigate(clickedItem?.mnsUrl);   
		}
	  };

	useEffect(() => {
		handleNotifications();
	}, [pathname, notifications, user]);

	useEffect(() => {}, [agencyObj, user]);

	useEffect(()=>{
		handleFetchMenuSidebarData()
	},[])

	const fullWidth = true;

	return (
		<>
			<div className='w-full max-h-full h-[107px]'>
				<div
					style={{
						boxShadow:
							'0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)',
					}}
					className='w-full h-[80px] bg-white flex items-center justify-between'>
					<div className='flex items-center'>
						<img
							src={emblem}
							className='ml-[10px] mr-[5px] object-contain w-[97px] h-[67px]'
							alt='kenya Emplem'
						/>
						<img
							className='h-10 w-auto'
							src={flagDivider}
							alt='kenya flag colors'
						/>
						<img
							src={neaLogo}
							className='ml-[10px] object-contain w-[166px] h-[50px]'
							alt='NEAIMIS Logo'
						/>
					</div>

					<div className='flex items-center'>
						<Dropdown
							overlayClassName='notification-dropdown'
							open={notOpen}
							overlayStyle={{
								minWidth: '300px',
								width: '400px',
								maxWidth: '100%',
							}}
							onOpenChange={(open) => {
								setnotOpen(open);
							}}
							menu={{
								items: notificationItems,
							}}
							arrow
							placement='bottom'
							trigger={['click']}>
							<Badge
								className='cursor-pointer'
								onClick={() => setnotOpen(!notOpen)}
								dot={
									notifications?.filter(
										(item) =>
											item?.ntfnStatus === 'UNREAD' ||
											item?.ntfnStatus === 'RECEIVED'
									)?.length
										? true
										: false
								}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='32'
									height='32'
									viewBox='0 0 32 32'
									fill='none'>
									<path
										d='M12 24C12 25.0609 12.4214 26.0783 13.1716 26.8284C13.9217 27.5786 14.9391 28 16 28C17.0609 28 18.0783 27.5786 18.8284 26.8284C19.5786 26.0783 20 25.0609 20 24'
										stroke='#343330'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
									<path
										d='M7.00009 13C7.00009 10.6131 7.9483 8.32387 9.63613 6.63604C11.324 4.94821 13.6131 4 16.0001 4C18.387 4 20.6762 4.94821 22.364 6.63604C24.0519 8.32387 25.0001 10.6131 25.0001 13C25.0001 17.4775 26.0376 21.075 26.8626 22.5C26.9502 22.6518 26.9964 22.8239 26.9966 22.9991C26.9967 23.1744 26.9508 23.3466 26.8635 23.4985C26.7761 23.6504 26.6504 23.7767 26.4989 23.8647C26.3473 23.9527 26.1753 23.9994 26.0001 24H6.00009C5.82506 23.9989 5.65338 23.952 5.5022 23.8638C5.35101 23.7756 5.22563 23.6492 5.13857 23.4974C5.05152 23.3456 5.00584 23.1735 5.0061 22.9985C5.00637 22.8235 5.05257 22.6516 5.14009 22.5C5.96384 21.075 7.00009 17.4763 7.00009 13Z'
										stroke='#343330'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
								</svg>
							</Badge>
						</Dropdown>

						<div className='mr-[2.94rem] rounded-full ml-[3rem] cursor-pointer'>
							<Dropdown
								overlayClassName='profile-dropdown'
								overlayStyle={{
									minWidth: '15.8125rem',
									width: 'auto',
									padding: 0,
								}}
								menu={{
									items,
								}}
								trigger={'click'}
								placement='bottomLeft'>
								<img
									loading='lazy'
									className='w-[2.5rem] h-[2.5rem] object-cover rounded-full'
									src={
										agencyObj?.instIcon
											? formatImgPath(agencyObj?.instIcon)
											: 'https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png'
									}
									alt='user-profile'
								/>
							</Dropdown>
						</div>
					</div>
				</div>

				<div className='flex'>
					<div className='hidden lg:block xl:w-[24%] lg:w-[34%] mt-7 ml-3 h-[85vh]  max-h-[100vh] bg-[#F8F8F8] p-3 cv-scroll overflow-y-scroll'>
						<div className='w-full max-w-sm bg-mainBg rounded-lg'>
							<div class='card'>
								<div class='cover-photo'>
									<img
										loading='lazy'
										style={{
											zIndex: '1000',
										}}
										src={
											agencyObj?.instIcon
												? formatImgPath(agencyObj?.instIcon)
												: 'https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png'
										}
										alt='profile'
										class='profile z-40 rounded'
									/>
								</div>
								<div className='flex flex-col items-center p-2'>
									<span className='profile-name mt-[19%]'>
										{agencyObj?.instName}
									</span>
									<span className='profile-email mt-2'>
										{agencyObj?.instEmail}
									</span>
									<span className='profile-no mt-2'>
										{agencyObj?.instWorkTel}
									</span>
									{user?.usrNeaVerified === 1 ||
									agencyObj?.instStatus === 'APPROVE_ACCOUNT_CREATION' ||
									agencyObj?.instStatus === 'DEREGISTRATION_REQUEST' ? (
										<span className='profile-status p-1 mt-2'>
											Status: Verified
										</span>
									) : agencyObj?.instStatus === 'REJECT_ACCOUNT_CREATION' ? (
										<span className='profile-status-rejected p-1 mt-2'>
											Status: Rejected
										</span>
									) : (
										<span className='profile-unstatus p-1 mt-2'>
											Status: Unverified
										</span>
									)}

									<div className='mt-3 mb-5'></div>
								</div>
							</div>
						</div>

						<div className='mt-4'>
							<Menu
								style={{
									height: !fullWidth ? '100vh' : 'auto',
									paddingTop: !fullWidth ? '20px' : '5px',
								}}
								defaultSelectedKeys={['1']}
								defaultOpenKeys={['sub1']}
								mode='inline'
								theme='light'
								inlineCollapsed={!fullWidth}
								items={menuItems}
								onClick={handleMenuClick}  
							/>
						</div>
					</div>
					<div className='w-full py-1 px-0 max-h-full h-[85vh] overflow-x-hidden overflow-y-auto mt-6'>
						{children}
					</div>
				</div>
			</div>
		</>
	);
}
